import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle,IonNote, IonToolbar, IonImg } from '@ionic/react';
import React from 'react';
import './Overview.css';

const Overview: React.FC = () => {

// const { name } = useParams<{ name: string; }>();

  return (
  <IonContent>
    <IonImg src="https://www.texasmonthly.com/wp-content/uploads/2019/01/25_TedCruz-1200x750.jpg" alt="a meme" className="portrait"></IonImg>
  </IonContent>
  )
  };

  export default Overview;