import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle,IonNote, IonToolbar, IonImg } from '@ionic/react';
import React from 'react';
import './Polypaths.css';

const Polypaths: React.FC = () => {

// const { name } = useParams<{ name: string; }>();

  return (
  <IonContent>
     {/* <embed type="text/html" src="https://www.google.com/" width="500" height="200"> </embed> */}
     <iframe className='webPage' name="samplePage" src="https://www.polypaths.com">
    </iframe>
  </IonContent>
  )
  };

  export default Polypaths;