import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,

} from '@ionic/react';
import React from 'react';
import { useLocation, Route } from 'react-router-dom';
import { archiveOutline, archiveSharp, bookmarkOutline, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import './Menu.css';
import Overview from '../pages/Overview';
import Polypaths from '../pages/Polypaths';

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  // Comp: Function;
  Comp: React.FC;
}
var typ = typeof Overview;
const appPages: AppPage[] = [
  {
    title: 'Overview',
    url: '/page/Overview',
    iosIcon: mailOutline,
    mdIcon: mailSharp,
    Comp: Overview
  },
  {
    title: 'Polypaths',
    url: '/page/Polypaths',
    iosIcon: paperPlaneOutline,
    mdIcon: paperPlaneSharp,
    Comp: Polypaths
  },
  {
    title: 'Favorites',
    url: '/page/Favorites',
    iosIcon: heartOutline,
    mdIcon: heartSharp,
    Comp: Overview
  },
  {
    title: 'Archived',
    url: '/page/Archived',
    iosIcon: archiveOutline,
    mdIcon: archiveSharp,
    Comp: Overview
  },
  {
    title: 'Trash',
    url: '/page/Trash',
    iosIcon: trashOutline,
    mdIcon: trashSharp,
    Comp: Overview
  },
  {
    title: 'Spam',
    url: '/page/Spam',
    iosIcon: warningOutline,
    mdIcon: warningSharp,
    Comp: Overview
  }
];

const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];

const Menu: React.FC = () => {
  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
    <IonContent>
   

        <IonList id="inbox-list">
          <IonListHeader>Polypaths Dashboard</IonListHeader>
          <IonNote>Cliff Weng</IonNote>
          {appPages.map((AppPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === AppPage.url ? 'selected' : ''} routerLink={AppPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" ios={AppPage.iosIcon} md={AppPage.mdIcon} />
                  <IonLabel>{AppPage.title}</IonLabel>
{/*   
                  <Route path={AppPage.url} component={AppPage.Comp} exact /> */}
                </IonItem>
              </IonMenuToggle>
            );
          })}
        </IonList>

        <IonList id="labels-list">
          <IonListHeader>Labels</IonListHeader>
          {labels.map((label, index) => (
            <IonItem lines="none" key={index}>
              <IonIcon slot="start" icon={bookmarkOutline} />
              <IonLabel>{label}</IonLabel>
            </IonItem>
          ))}
        </IonList>
      

    </IonContent>
        </IonMenu>
  );
};
const Rest: React.FC = () => {
  return(
    <IonContent>
    {appPages.map((AppPage, index) => {
      return (
        <Route path={AppPage.url} component={AppPage.Comp} exact />
      );
    })}
    </IonContent>
  );

  
};
export {Rest};
export default Menu;
